<template>
  <v-card>
    <v-card-title> Επεξεργασία Κατανομής </v-card-title>

    <v-divider></v-divider>

    <v-card-actions>
      <v-form class="flex-1" @submit.prevent="submit">
        <v-row>
          <v-col cols="6">
            <v-text-field
              disabled
              label="Έτος"
              v-model="form.financialQuotaYear"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              disabled
              label="Τρίμηνο"
              v-model="form.pdeSemester.code"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-text-field label="Ποσό" v-model="form.amount"></v-text-field>

        <v-text-field
          label="Αριθμός Πρωτοκόλλου"
          v-model="form.protocolNumber"
        ></v-text-field>

        <v-menu
          offset-y
          min-width="auto"
          v-model="protocolNumberDateMenu"
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <v-text-field
              readonly
              v-on="on"
              append-icon="mdi-calendar"
              label="Ημερομηνία Αρ. Πρωτοκόλλου"
              v-model="protocolNumberDateFormatted"
            ></v-text-field>
          </template>

          <v-date-picker
            v-model="form.protocolNumberDate"
            @input="protocolNumberDateMenu = false"
          ></v-date-picker>
        </v-menu>

        <v-row dense>
          <v-col cols="8">
            <v-file-input
              multiple
              clearable
              class="mr-2"
              label="Προσθήκη Συνημμένων Αρχείων"
              v-model="attachmentsToUpload"
            >
            </v-file-input>
          </v-col>

          <v-col cols="4" align-self="center">
            <v-btn
              text
              small
              outlined
              :disabled="attachmentsToUpload.length == 0"
              :loading="uploadLoader"
              @click="onUploadAttachments"
            >
              <v-icon left>mdi-upload</v-icon>
              Αρχειοθετηση
            </v-btn>
          </v-col>
        </v-row>

        <v-data-table
          v-if="
            [...uploadedAttachments, ...form.pdeJobFinancialQuotaAttachments]
              .length > 0
          "
          dense
          class="ml-8 mb-2"
          hide-default-header
          hide-default-footer
          :headers="[
            { value: 'filename' },
            { value: 'options', cellClass: 'text-end' },
          ]"
          :items="[
            ...uploadedAttachments,
            ...form.pdeJobFinancialQuotaAttachments,
          ]"
        >
          <template #[`item.options`]="{ item }">
            <v-btn
              icon
              small
              class="mr-1"
              :loading="downloadLoader == item.id"
              @click="onDownloadAttachment(item)"
            >
              <v-icon small>mdi-download</v-icon>
            </v-btn>

            <v-btn
              icon
              small
              :loading="deleteLoader == item.id"
              @click="onDeleteAttachment(item.id)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>

          <template #footer>
            <v-divider></v-divider>
          </template>
        </v-data-table>

        <v-btn text @click="$emit('closeDialog')"> Ακυρωση </v-btn>

        <v-btn
          text
          type="submit"
          color="primary darken-2"
          :loading="submitLoader"
        >
          Αποθηκευση
        </v-btn>
      </v-form>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";

export default {
  props: {
    editForm: {
      type: Object,
    },
  },
  data() {
    return {
      form: {
        amount: null,
        protocolNumber: null,
        protocolNumberDate: null,
        pdeJobFinancialQuotaAttachments: null,
      },
      submitLoader: false,
      uploadedAttachments: [],
      attachmentsToUpload: [],
      uploadLoader: false,
      downloadLoader: false,
      deleteLoader: false,
      protocolNumberDateMenu: false,
    };
  },
  computed: {
    protocolNumberDateFormatted() {
      if (!this.form.protocolNumberDate) return null;

      const [year, month, day] = this.form.protocolNumberDate.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  methods: {
    ...mapActions(["uploadFiles", "removeFile"]),
    // TODO
    async submit() {
      this.submitLoader = true;
      try {
      } catch (err) {
        console.log(err);
      } finally {
        this.submitLoader = false;
      }
    },
    async onUploadAttachments() {
      this.uploadLoader = true;
      try {
        // Create form data
        let formData = new FormData();
        this.attachmentsToUpload.forEach((file) => {
          formData.append("files", file, file.name);
        });

        // upload files
        let res = await this.uploadFiles(formData);

        // save file ids
        res.data.data.forEach((item) => {
          this.uploadedAttachments.push(item);
        });
        this.attachmentsToUpload = [];
      } catch (err) {
        console.log(err);
      } finally {
        this.uploadLoader = false;
      }
    },
    async onDownloadAttachment(item) {
      this.downloadLoader = true;
      try {
        let res = await axios(`/api/repository/file/download/${item.id}`, {
          responseType: "blob",
        });
        const fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", item.filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (err) {
        console.log(err);
      } finally {
        this.downloadLoader = false;
      }
    },
    async onDeleteAttachment(id) {
      this.deleteLoader = id;
      try {
        await axios.delete(`/api/repository/file/remove/${id}`, {
          data: {
            pdeJobId: this.$route.params.jobId,
          },
        });
        this.uploadedAttachments = this.uploadedAttachments.filter(
          (el) => el.id != id
        );
      } catch (err) {
        console.log(err);
      } finally {
        this.deleteLoader = false;
      }
    },
  },
  created() {
    this.form = { ...this.editForm };
  },
};
</script>

<style lang="scss" scoped></style>
