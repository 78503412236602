var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Προσθήκη Κατανομής ")]),_c('v-divider'),_c('v-card-actions',[_c('v-form',{staticClass:"flex-1",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"label":"Ποσό"},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}}),_c('v-text-field',{attrs:{"label":"Αριθμός Πρωτοκόλλου"},model:{value:(_vm.form.protocolNumber),callback:function ($$v) {_vm.$set(_vm.form, "protocolNumber", $$v)},expression:"form.protocolNumber"}}),_c('v-menu',{attrs:{"offset-y":"","min-width":"auto","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"readonly":"","append-icon":"mdi-calendar","label":"Ημερομηνία Αρ. Πρωτοκόλλου"},model:{value:(_vm.protocolNumberDateFormatted),callback:function ($$v) {_vm.protocolNumberDateFormatted=$$v},expression:"protocolNumberDateFormatted"}},on))]}}]),model:{value:(_vm.protocolNumberDateMenu),callback:function ($$v) {_vm.protocolNumberDateMenu=$$v},expression:"protocolNumberDateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.protocolNumberDateMenu = false}},model:{value:(_vm.form.protocolNumberDate),callback:function ($$v) {_vm.$set(_vm.form, "protocolNumberDate", $$v)},expression:"form.protocolNumberDate"}})],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-file-input',{staticClass:"mr-2",attrs:{"multiple":"","clearable":"","label":"Προσθήκη Συνημμένων Αρχείων"},model:{value:(_vm.attachmentsToUpload),callback:function ($$v) {_vm.attachmentsToUpload=$$v},expression:"attachmentsToUpload"}})],1),_c('v-col',{attrs:{"cols":"4","align-self":"center"}},[_c('v-btn',{attrs:{"text":"","small":"","outlined":"","disabled":_vm.attachmentsToUpload.length == 0,"loading":_vm.uploadLoader},on:{"click":_vm.onUploadAttachments}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-upload")]),_vm._v(" Αρχειοθετηση ")],1)],1)],1),(_vm.uploadedAttachments.length > 0)?_c('v-data-table',{staticClass:"ml-8 mb-2",attrs:{"hide-default-header":"","hide-default-footer":"","headers":[
          { value: 'filename' },
          { value: 'options', cellClass: 'text-end' } ],"items":_vm.uploadedAttachments},scopedSlots:_vm._u([{key:"item.options",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"ml-auto",attrs:{"icon":"","loading":_vm.deleteLoader == item.id},on:{"click":function($event){return _vm.onDeleteAttachment(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"footer",fn:function(){return [_c('v-divider')]},proxy:true}],null,true)}):_vm._e(),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.resetForm();
          _vm.$emit('closeDialog');}}},[_vm._v(" Ακυρωση ")]),_c('v-btn',{attrs:{"text":"","type":"submit","color":"primary darken-2","loading":_vm.submitLoader}},[_vm._v(" Αποθηκευση ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }