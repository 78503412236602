<template>
  <div class="mx-5">
    <v-breadcrumbs
      divider=">"
      :items="[
        { text: 'ΠΔΕ' },
        {
          text: 'ΕΡΓΑ',
          link: true,
          exact: true,
          to: '/pde/projects',
        },
        {
          text: this.jobTitle,
        },
      ]"
    >
    </v-breadcrumbs>

    <v-card>
      <v-tabs centered v-model="tab">
        <v-tab> Στοιχεια εργου </v-tab>

        <v-tab> Οικονομικα Στοιχεια Εργου </v-tab>

        <v-tab> Κατανομες </v-tab>

        <v-tab> Ιστορικο Τροποποιησεων κατανομων </v-tab>

        <v-tab> Υποεργα </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <pde-job-edit @setJobTitle="({ title }) => (jobTitle = title)" />
        </v-tab-item>

        <v-tab-item>
          <pde-job-financial-data-details />
        </v-tab-item>

        <v-tab-item>
          <pde-job-financial-quotas />
        </v-tab-item>

        <v-tab-item>
          <pde-job-financial-quotas-modifications />
        </v-tab-item>

        <v-tab-item>
          <pde-job-sub-jobs-list />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import PdeJobEdit from "./PdeJobViewTabs/PdeJobEdit.vue";
import PdeJobFinancialDataDetails from "./PdeJobViewTabs/FinancialDetails/FinancialDetailsView.vue";
import PdeJobFinancialQuotasModifications from "./PdeJobViewTabs/PdeJobFinancialQuotasModifications.vue";
import PdeJobFinancialQuotas from "./PdeJobViewTabs/FinancialQuotas/FinancialQuotasView.vue";
import PdeJobSubJobsList from "./PdeJobViewTabs/SubJobs/SubJobsListView.vue";

export default {
  components: {
    PdeJobEdit,
    PdeJobFinancialDataDetails,
    PdeJobFinancialQuotasModifications,
    PdeJobFinancialQuotas,
    PdeJobSubJobsList,
  },
  data() {
    return {
      tab: null,
      jobTitle: null,
    };
  },
};
</script>

<style lang="scss" scoped></style>
