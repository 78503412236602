<template>
  <div>
    <v-form @submit.prevent="submit" class="pa-4">
      <v-progress-linear
        v-if="!form.title"
        rounded
        indeterminate
        height="2"
        class="mb-4"
        color="#555555"
      ></v-progress-linear>

      <v-row>
        <v-col class="text-caption font-weight-light">
          ( * ) <v-icon small right>mdi-arrow-right</v-icon> Υποχρεωτικό Πεδίο
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4" class="pr-6">
          <v-text-field
            label="Τίτλος *"
            v-model="form.title"
            :error-messages="errorMessages.title"
          ></v-text-field>

          <v-autocomplete
            clearable
            label="Περιφερειακή ενότητα *"
            item-text="name"
            item-value="id"
            no-data-text="Δεν βρέθηκαν αποτελέσματα"
            v-model="form.unitId"
            :items="lists.units"
            :loading="lists.units.length == 0"
            :error-messages="errorMessages.unitId"
          ></v-autocomplete>

          <v-autocomplete
            clearable
            label="Απόφαση *"
            item-text="name"
            item-value="id"
            no-data-text="Δεν βρέθηκαν αποτελέσματα"
            v-model="form.pdeDecisionId"
            :items="lists.decisions"
            :loading="lists.decisions.length == 0"
            :error-messages="errorMessages.pdeDecisionId"
          ></v-autocomplete>

          <v-autocomplete
            clearable
            label="Είδος έργου *"
            item-text="name"
            item-value="id"
            no-data-text="Δεν βρέθηκαν αποτελέσματα"
            v-model="form.jobTypeId"
            :items="lists.jobTypes"
            :loading="lists.jobTypes.length == 0"
            :error-messages="errorMessages.jobTypeId"
          ></v-autocomplete>

          <v-autocomplete
            clearable
            label="Φάση έργου *"
            item-text="name"
            item-value="id"
            no-data-text="Δεν βρέθηκαν αποτελέσματα"
            v-model="form.jobPhaseId"
            :items="lists.jobPhases"
            :loading="lists.jobPhases.length == 0"
            :error-messages="errorMessages.jobPhaseId"
          ></v-autocomplete>

          <v-autocomplete
            clearable
            label="Υποτομέας *"
            item-text="name"
            item-value="id"
            no-data-text="Δεν βρέθηκαν αποτελέσματα"
            v-model="form.pdeSubsectorId"
            :items="lists.subsectors"
            :loading="lists.subsectors.length == 0"
            :error-messages="errorMessages.pdeSubsectorId"
          ></v-autocomplete>

          <v-text-field
            label="Προϋπολογισμός *"
            v-model="form.budget"
            :error-messages="errorMessages.budget"
          ></v-text-field>

          <v-text-field
            readonly
            label="Υπόλοιπο προϋπολογισμού"
            v-model="form.restBudget"
          ></v-text-field>
        </v-col>

        <v-col cols="4" class="pr-6">
          <v-autocomplete
            clearable
            label="Δήμος"
            item-text="name"
            item-value="id"
            no-data-text="Δεν βρέθηκαν αποτελέσματα"
            v-model="form.municipalityId"
            :items="lists.municipalities"
            :loading="lists.municipalities.length == 0"
          ></v-autocomplete>

          <v-text-field
            label="Κωδικός περιφερειακής ενότητας/δήμου"
            v-model="form.unitMunicipalityCodeId"
          ></v-text-field>

          <v-text-field
            label="Φορέας υλοποίησης"
            v-model="form.implementingEntity"
          ></v-text-field>

          <v-text-field
            label="Φορέας αρμοδιότητας"
            v-model="form.propertyEntity"
          ></v-text-field>

          <v-text-field
            label="Προϊσταμένη αρχή"
            v-model="form.supervisionAuthority"
          ></v-text-field>

          <v-text-field
            label="Διευθύνουσα υπηρεσία"
            v-model="form.managementService"
          ></v-text-field>

          <v-text-field
            label="Υπεύθυνος επικοινωνίας"
            v-model="form.contactDetails"
          ></v-text-field>

          <v-autocomplete
            clearable
            label="Φάση έργου αρχή έτους"
            item-text="name"
            item-value="id"
            no-data-text="Δεν βρέθηκαν αποτελέσματα"
            v-model="form.newYearJobPhaseId"
            :items="lists.jobPhases"
            :loading="lists.jobPhases.length == 0"
          ></v-autocomplete>
        </v-col>

        <v-col cols="4">
          <v-text-field
            label="Κωδικός Τράπεζας"
            v-model="form.bankCode"
          ></v-text-field>

          <v-text-field label="ΙΒΑΝ" v-model="form.iban"></v-text-field>

          <v-text-field label="ΣΑΕΠ" v-model="form.saepCode"></v-text-field>

          <v-text-field label="ΑΔΑ" v-model="form.ada"></v-text-field>

          <v-text-field label="ΚΑΕ" v-model="form.kae"></v-text-field>

          <v-text-field
            label="Έτος απένταξης"
            v-model="form.disengagementYear"
          ></v-text-field>

          <v-checkbox
            class="mb-2"
            label="Έχει οριστεί υπόλογος;"
            v-model="form.hasDefinedAccountable"
          ></v-checkbox>

          <v-text-field
            label="Υπόλογος"
            v-model="form.accountable"
            :disabled="!form.hasDefinedAccountable"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-divider class="my-8"></v-divider>

      <v-row justify="center">
        <v-col cols="4" class="pr-6">
          <v-text-field
            label="Αριθμός πρωτοκόλλου απόφασης"
            v-model="form.pdeDecisionProtocolNumber"
          ></v-text-field>

          <v-menu
            offset-y
            min-width="auto"
            v-model="decisionProtocolNumberDateMenu"
            :close-on-content-click="false"
          >
            <template #activator="{ on }">
              <v-text-field
                v-on="on"
                readonly
                persistent-hint
                hint="ΗΗ/ΜΜ/ΕΕΕΕ"
                append-icon="mdi-calendar"
                label="Ημερομηνία πρωτοκόλλου απόφασης"
                :value="decisionProtocolNumberDateFormatted"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.decisionProtocolNumberDate"
              @input="decisionProtocolNumberDateMenu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="4" class="pt-8 d-flex">
          <v-menu offset-y min-width="500px" :close-on-content-click="false">
            <template #activator="{ on: menu }">
              <v-btn small class="flex-1" v-on="{ ...menu }">
                αρχεια αριθμου πρωτοκολλου αποφασης
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card class="pa-2">
              <v-data-table
                dense
                hide-default-header
                hide-default-footer
                no-data-text="δεν υπάρχουν αρχεία"
                :headers="[
                  { value: 'filename' },
                  { value: 'options', cellClass: 'text-end' },
                ]"
                :items="attachments.decisionProtocol.uploaded"
              >
                <template #[`item.options`]="{ item }">
                  <v-btn
                    icon
                    small
                    class="mr-1"
                    :loading="downloadLoader == item.id"
                    @click="onDownloadAttachment(item)"
                  >
                    <v-icon small>mdi-download</v-icon>
                  </v-btn>

                  <v-btn
                    icon
                    small
                    :loading="deleteLoader == item.id"
                    @click="
                      onDeleteAttachment({
                        id: item.id,
                        type: 'decisionProtocol',
                      })
                    "
                  >
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </template>

                <template #footer>
                  <v-divider class="mb-2"></v-divider>
                  <v-row dense>
                    <v-col cols="8">
                      <v-file-input
                        multiple
                        clearable
                        class="mr-2"
                        label="Προσθήκη Συνημμένων Αρχείων"
                        v-model="attachments.decisionProtocol.toUpload"
                      >
                      </v-file-input>
                    </v-col>

                    <v-col cols="3" align-self="center">
                      <v-btn
                        text
                        small
                        outlined
                        :disabled="
                          attachments.decisionProtocol.toUpload.length == 0
                        "
                        :loading="uploadLoader == 'decisionProtocol'"
                        @click="onUploadAttachments('decisionProtocol')"
                      >
                        <v-icon left>mdi-upload</v-icon>
                        Αρχειοθετηση
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="4">
          <v-text-field
            label="ΑΔΑΜ πρωτογενούς αιτήματος"
            v-model="form.adamPrimaryRequest"
          ></v-text-field>
        </v-col>

        <v-col cols="4" class="pt-8 d-flex">
          <v-menu offset-y min-width="500px" :close-on-content-click="false">
            <template #activator="{ on: menu }">
              <v-btn small class="flex-1" v-on="{ ...menu }">
                αρχεια ΑΔΑΜ πρωτογενους αιτηματος
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card class="pa-2">
              <v-data-table
                dense
                hide-default-header
                hide-default-footer
                no-data-text="δεν υπάρχουν αρχεία"
                :headers="[
                  { value: 'filename' },
                  { value: 'options', cellClass: 'text-end' },
                ]"
                :items="attachments.adamPrimaryRequest.uploaded"
              >
                <template #[`item.options`]="{ item }">
                  <v-btn
                    icon
                    small
                    class="mr-1"
                    :loading="downloadLoader == item.id"
                    @click="onDownloadAttachment(item)"
                  >
                    <v-icon small>mdi-download</v-icon>
                  </v-btn>

                  <v-btn
                    icon
                    small
                    :loading="deleteLoader == item.id"
                    @click="
                      onDeleteAttachment({
                        id: item.id,
                        type: 'adamPrimaryRequest',
                      })
                    "
                  >
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </template>

                <template #footer>
                  <v-divider class="mb-2"></v-divider>
                  <v-row dense>
                    <v-col cols="8">
                      <v-file-input
                        multiple
                        clearable
                        class="mr-2"
                        label="Προσθήκη Συνημμένων Αρχείων"
                        v-model="attachments.adamPrimaryRequest.toUpload"
                      >
                      </v-file-input>
                    </v-col>

                    <v-col cols="3" align-self="center">
                      <v-btn
                        text
                        small
                        outlined
                        :disabled="
                          attachments.adamPrimaryRequest.toUpload.length == 0
                        "
                        :loading="uploadLoader == 'adamPrimaryRequest'"
                        @click="onUploadAttachments('adamPrimaryRequest')"
                      >
                        <v-icon left>mdi-upload</v-icon>
                        Αρχειοθετηση
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="4">
          <v-text-field
            label="ΑΔΑΜ εγκεκριμένου αιτήματος"
            v-model="form.adamConfirmedRequest"
          ></v-text-field>
        </v-col>

        <v-col cols="4" class="pt-8 d-flex">
          <v-menu offset-y min-width="500px" :close-on-content-click="false">
            <template #activator="{ on: menu }">
              <v-btn small class="flex-1" v-on="{ ...menu }">
                αρχεια ΑΔΑΜ εγκεκριμενου αιτηματος
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card class="pa-2">
              <v-data-table
                dense
                hide-default-header
                hide-default-footer
                no-data-text="δεν υπάρχουν αρχεία"
                :headers="[
                  { value: 'filename' },
                  { value: 'options', cellClass: 'text-end' },
                ]"
                :items="attachments.adamConfirmedRequest.uploaded"
              >
                <template #[`item.options`]="{ item }">
                  <v-btn
                    icon
                    small
                    class="mr-1"
                    :loading="downloadLoader == item.id"
                    @click="onDownloadAttachment(item)"
                  >
                    <v-icon small>mdi-download</v-icon>
                  </v-btn>

                  <v-btn
                    icon
                    small
                    :loading="deleteLoader == item.id"
                    @click="
                      onDeleteAttachment({
                        id: item.id,
                        type: 'adamConfirmedRequest',
                      })
                    "
                  >
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </template>

                <template #footer>
                  <v-divider class="mb-2"></v-divider>
                  <v-row dense>
                    <v-col cols="8">
                      <v-file-input
                        multiple
                        clearable
                        class="mr-2"
                        label="Προσθήκη Συνημμένων Αρχείων"
                        v-model="attachments.adamConfirmedRequest.toUpload"
                      >
                      </v-file-input>
                    </v-col>

                    <v-col cols="3" align-self="center">
                      <v-btn
                        text
                        small
                        outlined
                        :disabled="
                          attachments.adamConfirmedRequest.toUpload.length == 0
                        "
                        :loading="uploadLoader == 'adamConfirmedRequest'"
                        @click="onUploadAttachments('adamConfirmedRequest')"
                      >
                        <v-icon left>mdi-upload</v-icon>
                        Αρχειοθετηση
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>

      <v-divider class="my-8"></v-divider>

      <v-row justify="center">
        <v-col cols="8">
          <v-data-table
            dense
            hide-default-header
            hide-default-footer
            no-data-text="δεν υπάρχουν αρχεία"
            :headers="[
              { value: 'filename' },
              { value: 'options', cellClass: 'text-end' },
            ]"
            :items="attachments.job.uploaded"
          >
            <template #top>
              <span class="text-overline">Συνημμενα Αρχεια εργου</span>
              <v-divider></v-divider>
            </template>

            <template #[`item.options`]="{ item }">
              <v-btn
                icon
                small
                class="mr-1"
                :loading="downloadLoader == item.id"
                @click="onDownloadAttachment(item)"
              >
                <v-icon small>mdi-download</v-icon>
              </v-btn>

              <v-btn
                icon
                small
                :loading="deleteLoader == item.id"
                @click="onDeleteAttachment({ id: item.id, type: 'job' })"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </template>

            <template #footer>
              <v-divider class="mb-2"></v-divider>
              <v-row dense>
                <v-col cols="8">
                  <v-file-input
                    multiple
                    clearable
                    class="mr-2"
                    label="Προσθήκη Συνημμένων Αρχείων"
                    v-model="attachments.job.toUpload"
                  >
                  </v-file-input>
                </v-col>

                <v-col cols="3" align-self="center">
                  <v-btn
                    text
                    small
                    outlined
                    :disabled="attachments.job.toUpload.length == 0"
                    :loading="uploadLoader == 'job'"
                    @click="onUploadAttachments('job')"
                  >
                    <v-icon left>mdi-upload</v-icon>
                    Αρχειοθετηση
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-textarea
        outlined
        rows="2"
        label="Παρατηρήσεις"
        class="no-message mt-4"
        v-model="form.comments"
      ></v-textarea>

      <v-divider class="my-8"></v-divider>

      <v-row justify="center" class="pb-4">
        <v-btn
          rounded
          class="px-8"
          color="primary darken-2"
          type="submit"
          :loading="submitLoader"
        >
          Αποθηκευση
        </v-btn>
      </v-row>
    </v-form>

    <!-- TODO: handle route leave action -->
    <v-dialog v-model="unsavedChangesDialog" persistent max-width="380px">
      <v-card>
        <v-card-title> Έχετε μη αποθηκευμένες αλλαγές </v-card-title>

        <v-card-text> Είστε σίγουρος ότι θέλετε να τις ακυρώσετε; </v-card-text>

        <v-card-actions>
          <v-btn text @click="unsavedChangesDialog = false">πισω</v-btn>

          <v-btn text color="primary" @click="$router.push('/pde/projects')">
            Επιβεβαιωση
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      text
      content-class="font-weight-medium text-body-1"
      v-model="snackbar.open"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <template #action>
        <v-btn icon @click="snackbar.open = false">
          <v-icon color="grey darken-3">mdi-close-circle</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import { required, decimal } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      lists: {
        decisions: [],
        subsectors: [],
        units: [],
        municipalities: [],
        jobTypes: [],
        jobPhases: [],
      },
      form: {
        title: null,
        managementService: null,
        unitId: null,
        pdeDecisionId: null,
        bankCode: null,
        pdeDecisionProtocolNumber: null,
        pdeSubsectorId: null,
        jobTypeId: null,
        jobPhaseId: null,
        ada: null,
        hasDefinedAccountable: null,
        accountable: null,
        hasDefinedAccountable: null,
        adamPrimaryRequest: null,
        kae: null,
        implementingEntity: null,
        municipalityId: null,
        supervisionAuthority: null,
        iban: null,
        pdeDecisionProtocolNumberDate: null,
        unitMunicipalityCodeId: null,
        newYearJobPhaseId: null,
        disengagementYear: null,
        adamConfirmedRequest: null,
        saepCode: null,
        propertyEntity: null,
        unitMunicipalityCodeId: null,
        contactDetails: null,
        budget: null,
        comments: null,
        pdeJobAdamConfirmedRequestAttachments: [],
        pdeJobAdamPrimaryRequestAttachments: [],
        pdeJobDecisionProtocolAttachments: [],
        pdeJobAttachments: [],
      },
      attachments: {
        adamConfirmedRequest: {
          uploaded: [],
          toUpload: [],
        },
        adamPrimaryRequest: {
          uploaded: [],
          toUpload: [],
        },
        decisionProtocol: {
          uploaded: [],
          toUpload: [],
        },
        job: {
          uploaded: [],
          toUpload: [],
        },
      },
      snackbar: {
        open: false,
        text: null,
        color: null,
      },
      decisionProtocolNumberDateMenu: false,
      submitLoader: false,
      savedData: null,
      unsavedChangesDialog: false,
      uploadLoader: false,
      downloadLoader: false,
      deleteLoader: false,
    };
  },
  validations: {
    form: {
      title: { required },
      unitId: { required },
      pdeDecisionId: { required },
      jobTypeId: { required },
      jobPhaseId: { required },
      pdeSubsectorId: { required },
      budget: { required },
    },
  },
  computed: {
    errorMessages() {
      let errors = {
        title: [],
        unitId: [],
        budget: [],
        jobTypeId: [],
        jobPhaseId: [],
        pdeDecisionId: [],
        pdeSubsectorId: [],
      };
      for (let key in errors) {
        if (!this.$v.form[key]?.$dirty) continue;
        if (!this.$v.form[key]?.required) errors[key].push("Υποχρεωτικό πεδίο");
        // if (!this.$v.credits.decimal) errors.push("Πρέπει να είναι αριθμός");
      }
      return errors;
    },
    decisionProtocolNumberDateFormatted() {
      if (!this.form.decisionProtocolNumberDate) return null;
      const [year, month, day] =
        this.form.decisionProtocolNumberDate.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  methods: {
    ...mapActions(["uploadFiles", "removeFile"]),
    async submit() {
      this.submitLoader = true;
      try {
        // check validations
        this.$v.$touch();

        // show error message if unsuccessful
        if (this.$v.$invalid) {
          this.$store.commit("toggleSnackbar", {
            text: "Λανθασμένη Υποβολή",
          });
          return;
        }

        this.form.pdeJobAdamConfirmedRequestAttachmentsIds =
          this.attachments.adamConfirmedRequest.uploaded.map((el) => el.id);

        this.form.pdeJobAdamPrimaryRequestAttachmentsIds =
          this.attachments.adamPrimaryRequest.uploaded.map((el) => el.id);

        this.form.pdeJobDecisionProtocolAttachmentsIds =
          this.attachments.decisionProtocol.uploaded.map((el) => el.id);

        this.form.pdeJobAttachmentsIds = this.attachments.job.uploaded.map(
          (el) => el.id
        );

        await axios({
          method: "put",
          url: `/api/pde/job/edit/${this.form.id}`,
          data: this.form,
        });

        // show success message
        this.$store.commit("toggleSnackbar", {
          text: "Επιτυχής Υποβολή",
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.submitLoader = false;
      }
    },
    async onUploadAttachments(type) {
      this.uploadLoader = type;
      try {
        // Create form data
        let formData = new FormData();
        this.attachments[type].toUpload.forEach((file) => {
          formData.append("files", file, file.name);
        });

        // upload files
        let res = await this.uploadFiles(formData);

        // save file ids
        res.data.data.forEach((item) => {
          this.attachments[type].uploaded.push(item);
        });
        this.attachments[type].toUpload = [];
      } catch (err) {
        console.log(err);
      } finally {
        this.uploadLoader = false;
      }
    },
    async onDownloadAttachment(item) {
      this.downloadLoader = true;
      try {
        let res = await axios(`/api/repository/file/download/${item.id}`, {
          responseType: "blob",
        });
        const fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", item.filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (err) {
        console.log(err);
      } finally {
        this.downloadLoader = false;
      }
    },
    async onDeleteAttachment({ id, type }) {
      this.deleteLoader = id;
      try {
        await axios.delete(`/api/repository/file/remove/${id}`, {
          data: {
            pdeJobId: this.$route.params.jobId,
          },
        });
        this.attachments[type].uploaded = this.attachments[
          type
        ].uploaded.filter((el) => el.id != id);
      } catch (err) {
        console.log(err);
      } finally {
        this.deleteLoader = false;
      }
    },
    checkUnsavedChanges() {
      if (JSON.stringify(this.form) == JSON.stringify(this.savedData))
        this.$router.push("/pde/projects");

      this.unsavedChangesDialog = true;
      return true;
    },
  },
  async created() {
    try {
      // get job data
      let res = await axios(`/api/pde/job/${this.$route.params.jobId}`);

      // set form data
      this.form = { ...res.data.data };
      this.form.jobPhaseId = this.form.jobPhase.id;
      this.form.jobTypeId = this.form.jobType.id;
      this.form.pdeDecisionId = this.form.pdeDecision.id;
      this.form.pdeSubsectorId = this.form.pdeSubsector.id;
      this.form.unitId = this.form.unit.id;
      if (this.form.municipality)
        this.form.municipalityId = this.form.municipality.id;
      if (this.form.newYearJobPhase)
        this.form.newYearJobPhaseId = this.form.newYearJobPhase.id;

      // set attachments
      this.attachments.adamConfirmedRequest.uploaded =
        this.form.pdeJobAdamConfirmedRequestAttachments;
      this.attachments.adamPrimaryRequest.uploaded =
        this.form.pdeJobDecisionProtocolAttachments;
      this.attachments.decisionProtocol.uploaded =
        this.form.pdeJobAdamPrimaryRequestAttachments;
      this.attachments.job.uploaded = this.form.pdeJobAttachments;

      this.savedData = { ...this.form };

      // set page title
      this.$emit("setJobTitle", { title: this.form.title });

      // get lists
      let endpoints = [
        "/api/pde/decision/list",
        "/api/pde/subsector/list",
        "/api/unit/list",
        "/api/municipality/list",
        "/api/job/type/list",
        "/api/job/phase/list",
      ];
      let [
        { data: decisionRes },
        { data: subsectorRes },
        { data: unitRes },
        { data: municipalityRes },
        { data: jobTypeRes },
        { data: jobPhaseRes },
      ] = await Promise.all(endpoints.map((endpoint) => axios(endpoint)));

      this.lists.decisions = decisionRes.data;
      this.lists.subsectors = subsectorRes.data;
      this.lists.units = unitRes.data;
      this.lists.municipalities = municipalityRes.data;
      this.lists.jobTypes = jobTypeRes.data;
      this.lists.jobPhases = jobPhaseRes.data;
    } catch (err) {
      console.log(err);
    }
  },
  beforeRouteLeave(to, from) {
    if (checkUnsavedChanges) return false;
  },
};
</script>

<style lang="scss" scoped></style>
