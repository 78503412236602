<template>
  <v-card>
    <v-card-title> Προσθήκη Υποέργου </v-card-title>

    <v-divider></v-divider>

    <v-card-actions>
      <v-form class="flex-1" @submit.prevent="submit">
        <v-text-field label="Τίτλος *" v-model="form.title"></v-text-field>

        <v-text-field
          label="Κωδικός *"
          v-model="form.subPdeJobCode"
        ></v-text-field>

        <v-text-field
        type="number"
          label="Προϋπολογισμός"
          v-model="form.budget"
        ></v-text-field>

        <v-autocomplete
          clearable
          item-value="id"
          item-text="name"
          label="Φάση υποέργου *"
          v-model="form.jobPhaseId"
          :items="jobPhasesList"
          :loading="jobPhasesList.length == 0"
        ></v-autocomplete>

        <v-autocomplete
          clearable
          item-value="id"
          item-text="name"
          label="Φάση υποέργου αρχής έτους"
          v-model="form.newYearJobPhaseId"
          :items="jobPhasesList"
          :loading="jobPhasesList.length == 0"
        ></v-autocomplete>

        <v-autocomplete
          clearable
          item-value="id"
          item-text="name"
          label="Τύπος υποέργου *"
          v-model="form.jobTypeId"
          :items="jobTypesList"
          :loading="jobPhasesList.length == 0"
        ></v-autocomplete>

        <v-text-field
          label="Υπεύθυνος"
          v-model="form.contactDetails"
        ></v-text-field>

        <v-textarea
          rows="2"
          label='Σχόλια'
          v-model='form.comments'
        ></v-textarea>

        <v-btn
          text
          @click="
            resetForm();
            $emit('closeDialog');
          "
        >
          Ακυρωση
        </v-btn>

        <v-btn
          text
          type="submit"
          color="primary darken-2"
          :loading="submitLoader"
        >
          Αποθηκευση
        </v-btn>
      </v-form>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      form: {
        title: null,
        budget: null,
        jobTypeId: null,
        jobPhaseId: null,
        newYearJobPhaseId: null,
        contactDetails: null,
        comments: null,
        pdeJobId: this.$route.params.jobId,
      },
      jobPhasesList: [],
      jobTypesList: [],
      submitLoader: false,
    };
  },
  methods: {
    async submit() {
      this.submitLoader = true;
      try {
        await axios.post("/api/pde/sub/job/add", this.form);

        // refresh subJobs
        this.$emit("refreshSubJobs");

        // show success message
        this.$store.commit("toggleSnackbar", {
          open: true,
          text: "Το υποέργο προστέθηκε με επιτυχία",
        });

        // reset form and close dialog
        this.resetForm();
        this.$emit("closeDialog");
      } catch (err) {
        console.log(err);
      } finally {
        this.submitLoader = false;
      }
    },
    resetForm() {
      this.form = {
        title: null,
        budget: null,
        jobTypeId: null,
        jobPhaseId: null,
        newYearJobPhaseId: null,
        contactDetails: null,
        pdeJobId: this.$route.params.jobId,
      };
    },
  },
  async created() {
    let phases = await axios("/api/job/phase/list");
    let types = await axios("/api/job/type/list");

    this.jobPhasesList = phases.data.data;
    this.jobTypesList = types.data.data;
  },
};
</script>

<style lang="scss" scoped></style>
