var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-5"},[_c('v-breadcrumbs',{attrs:{"divider":">","items":[
      { text: 'ΠΔΕ' },
      {
        text: 'ΕΡΓΑ',
        link: true,
        exact: true,
        to: '/pde/projects',
      },
      {
        text: this.jobTitle,
      } ]}}),_c('v-card',[_c('v-tabs',{attrs:{"centered":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" Στοιχεια εργου ")]),_c('v-tab',[_vm._v(" Οικονομικα Στοιχεια Εργου ")]),_c('v-tab',[_vm._v(" Κατανομες ")]),_c('v-tab',[_vm._v(" Ιστορικο Τροποποιησεων κατανομων ")]),_c('v-tab',[_vm._v(" Υποεργα ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('pde-job-edit',{on:{"setJobTitle":function (ref) {
        var title = ref.title;

        return (_vm.jobTitle = title);
}}})],1),_c('v-tab-item',[_c('pde-job-financial-data-details')],1),_c('v-tab-item',[_c('pde-job-financial-quotas')],1),_c('v-tab-item',[_c('pde-job-financial-quotas-modifications')],1),_c('v-tab-item',[_c('pde-job-sub-jobs-list')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }