<template>
  <v-card>
    <v-card-title> Επεξεργασία Οικονομικών Στοιχείων </v-card-title>

    <v-divider></v-divider>

    <v-card-actions>
      <v-form class="flex-1" @submit.prevent="submit">
        <v-row dense>
          <v-col class="pa-0 pr-4" cols="6">
            <v-text-field
              type="number"
              label="Q1 *"
              v-model="form.q1Amount"
              :error-messages="errorMessages.q1Amount"
            ></v-text-field>
          </v-col>

          <v-col class="pa-0" cols="6">
            <v-text-field
              type="number"
              label="Q2 *"
              v-model="form.q2Amount"
              :error-messages="errorMessages.q2Amount"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col class="pa-0 pr-4" cols="6">
            <v-text-field
              type="number"
              label="Q3 *"
              v-model="form.q3Amount"
              :error-messages="errorMessages.q3Amount"
            ></v-text-field>
          </v-col>

          <v-col class="pa-0" cols="6">
            <v-text-field
              type="number"
              label="Q4 *"
              v-model="form.q4Amount"
              :error-messages="errorMessages.q4Amount"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-text-field
          label="Προτεινόμενο ΠΕΕ"
          v-model="form.suggestedPee"
        ></v-text-field>

        <v-text-field label="ΑΔΑ" v-model="form.ada"></v-text-field>

        <v-text-field
          label="Αριθμός Πρωτοκόλλου"
          v-model="form.protocolNumber"
        ></v-text-field>

        <v-menu
          offset-y
          min-width="auto"
          v-model="protocolNumberDateMenu"
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <v-text-field
              v-on="on"
              readonly
              persistent-hint
              hint="ΗΗ/ΜΜ/ΕΕΕΕ"
              class="mb-2"
              append-icon="mdi-calendar"
              label="Ημερομηνία Αρ. Πρωτοκόλλου"
              v-model="protocolNumberDateFormatted"
            ></v-text-field>
          </template>

          <v-date-picker
            v-model="form.protocolNumberDate"
            @input="protocolNumberDateMenu = false"
          ></v-date-picker>
        </v-menu>

        <v-row dense>
          <v-col cols="8">
            <v-file-input
              multiple
              clearable
              class="mr-2"
              label="Προσθήκη Συνημμένων Αρχείων"
              v-model="attachmentsToUpload"
            >
            </v-file-input>
          </v-col>

          <v-col cols="4" align-self="center">
            <v-btn
              text
              small
              outlined
              :disabled="attachmentsToUpload.length == 0"
              :loading="uploadLoader"
              @click="onUploadAttachments"
            >
              <v-icon left>mdi-upload</v-icon>
              Αρχειοθετηση
            </v-btn>
          </v-col>
        </v-row>

        <v-data-table
          v-if="
            [...uploadedAttachments, ...form.attachments]
              .length > 0
          "
          dense
          class="ml-8 mb-2"
          hide-default-header
          hide-default-footer
          :headers="[
            { value: 'filename' },
            { value: 'options', cellClass: 'text-end' },
          ]"
          :items="[
            ...uploadedAttachments,
            ...form.attachments,
          ]"
        >
          <template #[`item.options`]="{ item }">
            <v-btn
              icon
              small
              class="mr-1"
              :loading="downloadLoader == item.id"
              @click="onDownloadAttachment(item)"
            >
              <v-icon small>mdi-download</v-icon>
            </v-btn>

            <v-btn
              icon
              small
              :loading="deleteLoader == item.id"
              @click="onDeleteAttachment(item.id)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>

          <template #footer>
            <v-divider></v-divider>
          </template>
        </v-data-table>

        <v-btn
          text
          @click="
            resetForm();
            $emit('closeDialog');
          "
        >
          Ακυρωση
        </v-btn>

        <v-btn
          text
          type="submit"
          color="primary darken-2"
          :loading="submitLoader"
        >
          Αποθηκευση
        </v-btn>
      </v-form>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import { required, decimal } from "vuelidate/lib/validators";

export default {
  props: {
    editForm: {},
  },
  data() {
    return {
      form: {
        q1Amount: null,
        q2Amount: null,
        q3Amount: null,
        q4Amount: null,
        suggestedPee: null,
        protocolNumber: null,
        protocolNumberDate: null,
        ada: null,
        attachments: []
      },
      protocolNumberDateMenu: false,
      uploadedAttachments: [],
      attachmentsToUpload: [],
      uploadLoader: false,
      downloadLoader: false,
      deleteLoader: false,
      submitLoader: false,
    };
  },
  validations: {
    form: {
      q1Amount: { required },
      q2Amount: { required },
      q3Amount: { required },
      q4Amount: { required },
    },
  },
  computed: {
    protocolNumberDateFormatted() {
      if (!this.form.protocolNumberDate) return null;
      const [year, month, day] = this.form.protocolNumberDate.split("-");
      return `${day}/${month}/${year}`;
    },
    errorMessages() {
      let errors = {
        q1Amount: [],
        q2Amount: [],
        q3Amount: [],
        q4Amount: [],
      };
      for (let key in errors) {
        if (!this.$v.form[key]?.$dirty) continue;
        if (!this.$v.form[key]?.required) errors[key].push("Υποχρεωτικό πεδίο");
      }
      return errors;
    },
  },
  methods: {
    ...mapActions(["uploadFiles", "removeFile"]),
    resetForm() {
      this.form = {
        q1Amount: null,
        q2Amount: null,
        q3Amount: null,
        q4Amount: null,
        suggestedPee: null,
        protocolNumber: null,
        protocolNumberDate: null,
        ada: null,
      };
    },
    // TODO: testing
    async submit() {
      this.submitLoader = true;
      try {
        // check validations
        this.$v.$touch();

        // show error message if unsuccessful
        if (this.$v.$invalid) {
          this.$store.commit("toggleSnackbar", {
            text: "Λανθασμένη Υποβολή",
          });
          return;
        }

        await axios({
          method: "put",
          url: `/api/pde/job/financial/details/edit/${this.$route.params.jobId}`,
          data: this.form,
        });

        // refresh financial details
        this.$emit("refreshFinancialDetails");

        // show success message
        this.$store.commit("toggleSnackbar", {
          open: true,
          text: "Τα οικονομικά στοιχεία προστέθηκαν με επιτυχία",
        });

        // reset form and close dialog
        this.resetForm();
        this.$emit("closeDialog");
      } catch (err) {
        console.log(err);
      } finally {
        this.submitLoader = false;
      }
    },
    async onUploadAttachments() {
      this.uploadLoader = true;
      try {
        // Create form data
        let formData = new FormData();
        this.attachmentsToUpload.forEach((file) => {
          formData.append("files", file, file.name);
        });

        // upload files
        let res = await this.uploadFiles(formData);

        // save file ids
        res.data.data.forEach((item) => {
          this.uploadedAttachments.push(item);
        });
        this.attachmentsToUpload = [];
      } catch (err) {
        console.log(err);
      } finally {
        this.uploadLoader = false;
      }
    },
    async onDownloadAttachment(item) {
      this.downloadLoader = true;
      try {
        let res = await axios(`/api/repository/file/download/${item.id}`, {
          responseType: "blob",
        });
        const fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", item.filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (err) {
        console.log(err);
      } finally {
        this.downloadLoader = false;
      }
    },
    async onDeleteAttachment(id) {
      this.deleteLoader = id;
      try {
        await axios.delete(`/api/repository/file/remove/${id}`, {
          data: {
            pdeJobId: this.$route.params.jobId,
          },
        });
        this.uploadedAttachments = this.uploadedAttachments.filter(
          (el) => el.id != id
        );
      } catch (err) {
        console.log(err);
      } finally {
        this.deleteLoader = false;
      }
    },
  },
  created() {
    this.form = this.editForm;
  },
};
</script>

<style lang="scss" scoped></style>
