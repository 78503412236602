var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3"},[_c('v-data-table',{attrs:{"no-data-text":"Δεν Βρέθηκαν Τροποποιήσεις","headers":_vm.headers,"items":_vm.itemsList,"loading":_vm.itemsLoader},scopedSlots:_vm._u([{key:"item.protocolNumberDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.protocolNumberDateFormatted(item.protocolNumberDate))+" ")]}},{key:"item.files",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, menu, tooltip)),[_c('v-icon',[_vm._v("mdi-file-multiple-outline")])],1)]}}],null,true)},[_vm._v(" Συνημμένα αρχεία ")])]}}],null,true)},[_c('v-card',[(item.pdeJobFinancialQuotaModificationAttachments.length > 0)?_c('v-list',_vm._l((item.pdeJobFinancialQuotaModificationAttachments),function(attachment){return _c('v-list-item',{key:attachment.id},[_c('v-list-item-title',[_vm._v(" "+_vm._s(attachment.filename)+" ")]),_c('v-list-item-icon',[_c('v-btn',{attrs:{"icon":"","loading":_vm.downloadLoader},on:{"click":function($event){return _vm.onDownloadAttachment(attachment)}}},[_c('v-icon',[_vm._v("mdi-download")])],1)],1)],1)}),1):_vm._e(),_c('v-card-text',[_vm._v(" Δεν υπάρχουν συνημμένα Αρχεία ")])],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }