<template>
  <div class="pa-3">
    <v-data-table
      no-data-text="Δεν Βρέθηκαν Τροποποιήσεις"
      :headers="headers"
      :items="itemsList"
      :loading="itemsLoader"
    >
      <template #[`item.protocolNumberDate`]="{ item }">
        {{ protocolNumberDateFormatted(item.protocolNumberDate) }}
      </template>

      <template #[`item.files`]="{ item }">
        <v-menu offset-y :close-on-content-click="false">
          <template #activator="{ on: menu }">
            <v-tooltip top>
              <template #activator="{ on: tooltip }">
                <v-btn icon v-on="{ ...menu, ...tooltip }">
                  <v-icon>mdi-file-multiple-outline</v-icon>
                </v-btn>
              </template>
              Συνημμένα αρχεία
            </v-tooltip>
          </template>
          <v-card>
            <v-list
              v-if="item.pdeJobFinancialQuotaModificationAttachments.length > 0"
            >
              <v-list-item
                v-for="attachment in item.pdeJobFinancialQuotaModificationAttachments"
                :key="attachment.id"
              >
                <v-list-item-title>
                  {{ attachment.filename }}
                </v-list-item-title>

                <v-list-item-icon>
                  <v-btn
                    icon
                    :loading="downloadLoader"
                    @click="onDownloadAttachment(attachment)"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>
            </v-list>

            <v-card-text> Δεν υπάρχουν συνημμένα Αρχεία </v-card-text>
          </v-card>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      headers: [
        {
          text: "Q1",
          value: "q1Amount",
        },
        {
          text: "Q2",
          value: "q1Amount",
        },
        {
          text: "Q3",
          value: "q3Amount",
        },
        {
          text: "Q4",
          value: "q4Amount",
        },
        {
          text: "Αριθμός πρωτοκόλλου",
          value: "protocolNumber",
        },
        {
          text: "Ημερομηνία αριθμού πρωτοκόλλου",
          value: "protocolNumberDate",
        },
        {
          text: "ΑΔΑ",
          value: "ada",
        },
        {
          value: "files",
          width: "50px",
          sortable: false,
        },
      ],
      itemsList: [],
      itemsLoader: false,
      downloadLoader: false,
    };
  },
  methods: {
    async onDownloadAttachment(item) {
      this.downloadLoader = true;
      try {
        let res = await axios(`/api/repository/file/download/${item.id}`, {
          responseType: "blob",
        });
        const fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", item.filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (err) {
        console.log(err);
      } finally {
        this.downloadLoader = false;
      }
    },
    async getFinancialQuotasModifications() {
      this.itemsLoader = true;
      try {
        let res = await axios(
          "/api/pde/job/financial/quota/modification/list",
          {
            params: {
              pdeJobId: this.$route.params.jobId,
            },
          }
        );
        this.itemsList = res.data.data;
      } catch (err) {
        console.log(err);
      } finally {
        this.itemsLoader = false;
      }
    },
    protocolNumberDateFormatted(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  async created() {
    this.getFinancialQuotasModifications();
  },
};
</script>

<style lang="scss" scoped></style>
