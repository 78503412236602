var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"500","close-on-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"my-2 ml-3",attrs:{"tile":"","text":"","small":"","outlined":"","color":"primary darken-2"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Προσθηκη Κατανομης ")],1)]}}]),model:{value:(_vm.addFinancialQuotaDialog),callback:function ($$v) {_vm.addFinancialQuotaDialog=$$v},expression:"addFinancialQuotaDialog"}},[_c('financial-quota-add',{on:{"closeDialog":function($event){_vm.addFinancialQuotaDialog = false},"refreshQuotas":_vm.getQuotas}})],1),_c('v-data-table',{attrs:{"no-data-text":"Δεν Βρέθηκαν Κατανομές","headers":_vm.headers,"items":_vm.quotas,"loading":_vm.quotasLoader},scopedSlots:_vm._u([{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"persistent":"","max-width":"500","close-on-click":false,"value":_vm.financialQuotaEditForm == item},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [(item.pdeSemester.id == _vm.currentSemester)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.financialQuotaEditForm = item}}},Object.assign({}, dialog, tooltip)),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pen")])],1)]}}],null,true)},[_vm._v(" Επεξεργασία ")]):_vm._e()]}}],null,true)},[_c('financial-quota-edit',{attrs:{"edit-form":_vm.financialQuotaEditForm},on:{"closeDialog":function($event){_vm.financialQuotaEditForm = null},"refreshQuotas":_vm.getQuotas}})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }