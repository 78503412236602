<template>
  <div class="pa-3">
    <v-dialog
      persistent
      max-width="500"
      :close-on-click="false"
      v-model="addFinancialQuotaDialog"
    >
      <template #activator="{ on }">
        <v-btn
          tile
          text
          small
          outlined
          color="primary darken-2"
          class="my-2 ml-3"
          v-on="on"
        >
          <v-icon left>mdi-plus</v-icon>
          Προσθηκη Κατανομης
        </v-btn>
      </template>

      <financial-quota-add
        @closeDialog="addFinancialQuotaDialog = false"
        @refreshQuotas="getQuotas"
      />
    </v-dialog>

    <v-data-table
      no-data-text="Δεν Βρέθηκαν Κατανομές"
      :headers="headers"
      :items="quotas"
      :loading="quotasLoader"
    >
      <template #[`item.options`]="{ item }">
        <!-- Show edit button only for current year entries -->
        <v-dialog
          persistent
          max-width="500"
          :close-on-click="false"
          :value="financialQuotaEditForm == item"
        >
          <template #activator="{ on: dialog }">
            <v-tooltip v-if="item.pdeSemester.id == currentSemester" top>
              <template #activator="{ on: tooltip }">
                <v-btn
                  icon
                  v-on="{ ...dialog, ...tooltip }"
                  @click="financialQuotaEditForm = item"
                >
                  <v-icon small>mdi-pen</v-icon>
                </v-btn>
              </template>
              Επεξεργασία
            </v-tooltip>
          </template>

          <financial-quota-edit
            :edit-form="financialQuotaEditForm"
            @closeDialog="financialQuotaEditForm = null"
            @refreshQuotas="getQuotas"
          />
        </v-dialog>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import FinancialQuotaAdd from "./FinancialQuotaAdd.vue";
import FinancialQuotaEdit from "./FinancialQuotaEdit.vue";

export default {
  components: {
    FinancialQuotaAdd,
    FinancialQuotaEdit,
  },
  data() {
    return {
      quotas: [],
      headers: [
        {
          value: "options",
          width: "70px",
          sortable: false,
        },
        {
          text: "Έτος",
          value: "financialQuotaYear",
        },
        {
          text: "Τρίμηνο",
          value: "pdeSemester.code",
        },
        // TODO: add protocolNumberDate
        {
          text: "Αριθμός Πρωτοκόλλου",
          value: "protocolNumber",
        },
        {
          text: "Ποσό",
          value: "amount",
        },
        {
          text: "Σύνολο",
          value: "currentFinancialQuota",
        },
      ],
      addFinancialQuotaDialog: false,
      financialQuotaEditForm: null,
      quotasLoader: false,
      currentSemester: Math.floor((new Date().getMonth() + 3) / 3),
    };
  },
  methods: {
    async getQuotas() {
      this.quotasLoader = true;
      try {
        let res = await axios("/api/pde/job/financial/quota/list", {
          params: { pdeJobId: this.$route.params.jobId },
        });
        this.quotas = res.data.data;
      } catch (err) {
        console.log(err);
      } finally {
        this.quotasLoader = false;
      }
    },
  },
  async created() {
    this.getQuotas();
  },
};
</script>

<style lang="scss" scoped></style>
