<template>
  <div class="pa-3">
    <v-btn
      tile
      text
      small
      outlined
      color="primary darken-2"
      class="my-2 ml-3"
      @click="financialDetailsAddDialog = true"
    >
      <v-icon left>mdi-plus</v-icon>
      Προσθηκη Οικονομικων στοιχειων
    </v-btn>

    <v-data-table
      no-data-text="Δεν Βρέθηκαν Οικονομικά Στοιχεία"
      :headers="headers"
      :items="items"
      :loading="loader"
    >
      <template #[`item.edit`]="{ item }">
        <v-tooltip v-if="item.financialCreditsYear == currentYear" top>
          <template #activator="{ on: tooltip }">
            <v-btn
              icon
              @click="
                financialDetailsEditDialog = true;
                financialDetailsEditForm = item;
              "
              v-on="{ ...tooltip }"
            >
              <v-icon small>mdi-pen</v-icon>
            </v-btn>
          </template>

          Επεξεργασία
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog persistent width="500px" v-model="financialDetailsAddDialog">
      <financial-details-add
        @closeDialog="financialDetailsAddDialog = false"
        @refreshFinancialDetails="getFinancialDetails()"
      />
    </v-dialog>

    <v-dialog persistent width="500px" v-model="financialDetailsEditDialog">
      <financial-details-edit
        :editForm="financialDetailsEditForm"
        @closeDialog="financialDetailsEditDialog = false"
        @refreshFinancialDetails="getFinancialDetails()"
      />
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import FinancialDetailsAdd from "./FinancialDetailsAdd.vue";
import FinancialDetailsEdit from "./FinancialDetailsEdit.vue";

export default {
  components: {
    FinancialDetailsAdd,
    FinancialDetailsEdit,
  },
  data() {
    return {
      items: [],
      headers: [
        {
          value: "edit",
          sortable: false,
        },
        {
          text: "Έτος",
          value: "financialCreditsYear",
          cellClass: "font-weight-medium text-body-1",
          width: "80px",
        },
        {
          text: "Υπόλοιπο πιστώσεων προηγούμενου έτους",
          value: "previousYearRestCredits",
        },
        {
          text: "Σύνολο κατανομών προηγούμενου έτους",
          value: "previousYearFinancialQuota",
        },
        {
          text: "Σύνολο κατανομών προηγούμενων ετών",
          value: "totalPreviousFinancialQuota",
        },
        {
          text: "Πληρωμές μέχρι 31/12 προηγούμενου έτους",
          value: "totalPreviousYearPayments",
        },
        {
          text: "Προϋπολογισμός",
          value: "budget",
        },
        {
          text: "Q1",
          value: "q1Amount",
        },
        {
          text: "Q2",
          value: "q2Amount",
        },
        {
          text: "Q3",
          value: "q3Amount",
        },
        {
          text: "Q4",
          value: "q4Amount",
        },
        {
          text: "Εγκεκριμένες πιστώσεις έτους",
          value: "currentYearConfirmedCredits",
        },
        {
          text: "Εγκεκριμένες πιστώσεις τρέχοντος τριμήνου",
          value: "currentSemesterConfirmedCredits",
        },
        {
          text: "Διαθέσιμο υπόλοιπο πιστώσεων τρέχοντος τριμήνου",
          value: "currentSemesterRestCredits",
        },
        {
          text: "Κατανομές έτους",
          value: "currentYearFinancialQuota",
        },
        {
          text: "Υπόλοιπο προϋπολογισμού",
          value: "restBudget",
        },
        {
          text: "Εγκεκριμένο ΠΕΕ",
          value: "confirmedPee",
        },
        {
          text: "Προτεινόμενο ΠΕΕ",
          value: "suggestedPee",
        },
        {
          text: "Μεταβολή ΠΕΕ",
          value: "variationPee",
        },
      ],
      currentYear: new Date().getFullYear(),
      loader: false,
      financialDetailsAddDialog: false,
      financialDetailsEditDialog: false,
      financialDetailsEditForm: null,
    };
  },
  methods: {
    async getFinancialDetails() {
      this.loader = true;
      try {
        const res = await axios(
          `/api/pde/job/financial/details/pdejob/${this.$route.params.jobId}`
        );
        this.items = res.data.data;
      } catch (err) {
        console.log(err);
      } finally {
        this.loader = false;
      }
    },
  },
  async created() {
    this.getFinancialDetails();
  },
};
</script>

<style lang="scss" scoped></style>
