<template>
  <div class="pa-3">
    <v-btn
      tile
      text
      small
      outlined
      color="primary darken-2"
      class="my-2 ml-3"
      @click="addSubJobDialog = true"
    >
      <v-icon left>mdi-plus</v-icon>
      Προσθηκη Υποεργου
    </v-btn>

    <v-data-table
      no-data-text="Δεν Βρέθηκαν Υποέργα"
      :headers="headers"
      :items="subJobs"
      :loading="subJobsLoader"
    >
      <template #[`item.open`]="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn
              icon
              @click="
                $router.push(`/pde/projects/${pdeJobId}/sub-job/${item.id}`)
              "
              v-on="on"
            >
              <v-icon small>mdi-open-in-app</v-icon>
            </v-btn>
          </template>
          Προβολή
        </v-tooltip>
      </template>

      <template #[`item.delete`]="{ item }">
        <v-tooltip top>
          <template #activator="{ on: tooltip }">
            <v-menu
              offset-y
              :close-on-content-click="false"
              :value="removeSubJob == item"
            >
              <template #activator="{ on: menu }">
                <v-btn
                  icon
                  @click="removeSubJob = item"
                  v-on="{ ...tooltip, ...menu }"
                >
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title> Διαγραφή Υποέργου </v-card-title>

                <v-card-actions>
                  <v-btn text @click="removeSubJob = null">Ακυρωση</v-btn>

                  <v-btn
                    text
                    @click="onRemoveSubJob(item.id)"
                    :loading="removeSubJobLoader"
                  >
                    Επιβεβαιωση
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </template>
          Διαγραφή
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog permanent width="500" v-model="addSubJobDialog">
      <sub-job-add
        @refreshSubJobs="getSubJobs"
        @closeDialog="addSubJobDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import SubJobAdd from "./SubJobAdd.vue";

export default {
  components: {
    SubJobAdd,
  },
  data() {
    return {
      pdeJobId: this.$route.params.jobId,
      subJobs: [],
      headers: [
        {
          value: "open",
          width: "70px",
          sortable: false,
        },
        {
          text: "Α/Α",
          value: "incrementalNumber",
          width: "80px",
        },
        {
          text: "Τίτλος",
          value: "title",
        },
        {
          text: "Κωδικός υποέργου",
          value: "subPdeJobCode",
        },
        {
          text: "Προϋπολογισμός",
          value: "budget",
        },
        {
          text: "Φάση έργου",
          value: "jobPhase.name",
        },
        {
          text: "Είδος έργου",
          value: "jobType.name",
        },
        {
          text: "Υπεύθυνος υποέργου",
          value: "contactDetails",
          sortable: false,
        },
        {
          text: "Σχόλια",
          value: "comments",
          sortable: false,
        },
        {
          value: "delete",
          width: "70px",
          sortable: false,
        },
      ],
      listPage: 1,
      subJobsLoader: false,
      addSubJobDialog: false,
      removeSubJob: null,
      removeSubJobLoader: null,
    };
  },
  methods: {
    async getSubJobs() {
      this.subJobsLoader = true;
      try {
        let res = await axios(`/api/pde/sub/job/list/paging/${this.pdeJobId}`, {
          params: {
            page: this.listPage,
          },
        });
        this.subJobs = res.data.data;
      } catch (err) {
        console.log(err);
      } finally {
        this.subJobsLoader = false;
      }
    },
    async onRemoveSubJob(id) {
      this.removeSubJobLoader = true;
      try {
        await axios.delete(`/api/pde/sub/job/${id}`);
        await this.getSubJobs();
        this.removeSubJob = null;
        this.$store.commit("toggleSnackbar", {
          open: true,
          text: "Η διαγραφή πραγματοποιήθηκε με επιτυχία!",
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.removeSubJobLoader = false;
      }
    },
  },
  created() {
    this.getSubJobs();
  },
};
</script>

<style lang="scss" scoped></style>
