var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3"},[_c('v-btn',{staticClass:"my-2 ml-3",attrs:{"tile":"","text":"","small":"","outlined":"","color":"primary darken-2"},on:{"click":function($event){_vm.addSubJobDialog = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Προσθηκη Υποεργου ")],1),_c('v-data-table',{attrs:{"no-data-text":"Δεν Βρέθηκαν Υποέργα","headers":_vm.headers,"items":_vm.subJobs,"loading":_vm.subJobsLoader},scopedSlots:_vm._u([{key:"item.open",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push(("/pde/projects/" + _vm.pdeJobId + "/sub-job/" + (item.id)))}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-app")])],1)]}}],null,true)},[_vm._v(" Προβολή ")])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"value":_vm.removeSubJob == item},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.removeSubJob = item}}},Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v(" Διαγραφή Υποέργου ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.removeSubJob = null}}},[_vm._v("Ακυρωση")]),_c('v-btn',{attrs:{"text":"","loading":_vm.removeSubJobLoader},on:{"click":function($event){return _vm.onRemoveSubJob(item.id)}}},[_vm._v(" Επιβεβαιωση ")])],1)],1)],1)]}}],null,true)},[_vm._v(" Διαγραφή ")])]}}],null,true)}),_c('v-dialog',{attrs:{"permanent":"","width":"500"},model:{value:(_vm.addSubJobDialog),callback:function ($$v) {_vm.addSubJobDialog=$$v},expression:"addSubJobDialog"}},[_c('sub-job-add',{on:{"refreshSubJobs":_vm.getSubJobs,"closeDialog":function($event){_vm.addSubJobDialog = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }